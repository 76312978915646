<template>
	<section class="section">
		<base-row class="row">
			<base-col class="card-wrapper" v-for="(item, i) in value.paragraphs" :key="i" col="12">
				<div class="product-content">
					<base-font class="title" variant="paragraph" tag="h2" weight="bold" uppercase>
						{{item.heading}}
					</base-font>
					<div class="product-text" v-html="item.paragraph"></div>
				</div>
			</base-col>
		</base-row>
	</section>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.row {
	margin-top: 8rem;
	row-gap: 3rem;

	::v-deep .card-wrapper {
		flex: 1 0 33%;
		width: auto;
	}
}
.product-content {
	background: #fff;
	padding: 3rem;
	border: 1px solid $gray-100;
	flex-wrap: wrap;
	height: 100%;

	@include media-breakpoint-up(lg) {
		min-height: 310px;
	}

	@include media-breakpoint-up(xl) {
		min-height: 210px;
	}

	& :deep(.title) {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		&::after {
			content: '';
			flex-grow: 1;
			margin-left: 2rem;
			height: 2px;
			background-color: #F0EEEC;
		}
	}
	.product-text {
		font-family: $primary-font;
	}
}
</style>
